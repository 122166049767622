/* iPhone 6/6s */
@media screen and (max-width: 768px){
    .ifr-sec{
        border: 4px solid rgb(246, 234, 234, .5);
    }
    
    .main-text {
        padding: 10px 0px;
        letter-spacing: 0;
    }

    .main-text p {
        font-weight: bold;
        font-size: 16px;
     }

    .search-sec input[type="text"] {
        padding: 5px;
        height: auto;
        line-height: 1;
        font-weight: 300;
    }

    .search-sec input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #0cc1b8;
        font-size: 18px;
        height: auto;
        line-height: 1;
    }
    
    .search-sec input[type="text"]::-moz-placeholder { /* Firefox 19+ */
        color: #0cc1b8;
        font-size: 18px;
        height: auto;
        line-height: 1;
    }
    
    .search-sec input[type="text"]:-ms-input-placeholder { /* IE 10+ */
        color: #0cc1b8;
        font-size: 18px;
        height: auto;
        line-height: 1;
    }
    
    .search-sec input[type="text"]:-moz-placeholder { /* Firefox 18- */
        color: #0cc1b8;
        font-size: 18px;
        height: auto;
        line-height: 1;
    }
    
    .search-sec-icon img {
        right: 10px;
        top: 10px;
        width: 16px;
        height: 16px;
    }

    .dropdown-wrapper {
        margin: 15px 0px 10px;
    }

    .select-container {
        margin-top: -5px;
        width: 100%;
    }

    .search-form {
        width: 100%;
    }

    .month-of {
        margin-top: 22px;
    }

    .month-of select {
        padding: 3px 10px;
        font-size: 20px;
    }

    .static-centres-block {
        padding: 1% 0%;
      }

    .pass-rate-section, .pass-rate-section1 {
        display: none;
    }

    .button-sec button[type="button"]{
        font-size: 20px;
        font-weight: 300;
        padding: 4px 36px;
      }
      

    .main-bd-upper h5 {
        width: 100%;
        margin: 0;
        font-size: 16px;
    }
    
    .share-icons {
        margin-top: -5px;
    }

    .rate-box-wrapper {
        margin: 10px 1px;        
    }
    .rate-box {
        padding: 0;
    }

    .rate-box-title{
        padding: 15px 0.7vw;
        height: auto;
    }

    .rate-box:nth-child(1), .rate-box:nth-child(2) {
        margin-bottom: 30px;
    }

    .rate-box-content {
        height: auto;
        font-weight: 300;
        padding: 12px 8px;
    }

    .pass-rate-stat {
        margin-top: 20px;
    }

    .pass-rate-stat h6 {
        font-size: 14px;
        font-weight: 400;
    }

    .rate-box:nth-child(1) .rate-box-content {
        padding: 0vh 2vw;
        height: 170px;
    }

    .progress-bar { 
        height: 30px;
        line-height: 30px;
        justify-content: center;
    }

    .prob-progress { 
        width: 100%;
    }

    .prob-progress .progress {
        height: 30px;
    }

    .prob-progress .progress-bar{
        font-size: 16px;
    }
      
    .spacer {
        line-height: 1.5;
    }  

    .frame-footer-text {
        margin: 20px 0;  
    }

    .out-fr-heading-txt{
        font-size: 18px;
        font-weight: 500;
    }

    .out-fr-first-txt {
        font-size: 14px;
        width: auto;
    }

    .out-fr-first-txt1 {
        font-size: 14px;
        width: auto;
        margin-top: 20px; 
    }

    .out-fr-second-txt1, .out-fr-third-txt, .out-fr-fifth-txt, .out-fr-sign-now {
        font-size: 14px;
        width: auto;
        margin-top: 20px;
    }
    
    .out-fr-second-txt {
        font-size: 14px;
        color: #8B8B8B;
        margin-top: 20px;
        font-weight: normal;
    }
    
    .out-fr-fourth-txt {
        font-size: 20px;
        margin-top: 20px;
        color: #e95e26;
    }
    
    .out-fr-practice-info-wrapper {
        width: 60%;
        text-align: center;
        margin: 0 auto;
    }
    
    .out-fr-sign-now a {
        color: black;
        font-size: 18px;
        text-decoration: underline;
        text-underline-position: under;
    }
    
    .out-fr-learner-wrapper {
        background-color: #0CC1B8;
        padding: 50px;
        margin-top: 20px;
        color: white;
        /* font-size: 18px; */
    }
    
    .out-fr-learner-wrapper-btn-div {
        background-color: #e95e26;
        padding: 10px;
        width:auto;
        display:inline-block;
    }
    
    .out-fr-learner-wrapper a {
        color: white;
        font-size: 16px;
        font-weight: normal;
    }
    
    .out-fr-learner-wrapper a:hover {
        text-decoration: none;
    }
    
    .out-fr-practice-info-wrapper .out-fr-practice-info-content {
        margin-top: 30px;
    }
    
    .out-fr-practice-info-wrapper > div {
        text-align: center;
        color: #0CC1B8;
    }

    .out-fr-practice-info-wrapper .out-fr-practice-info-content div:nth-child(2) {
        width: auto;
        font-size: 14px;
    }

    .out-fr-practice-info-wrapper {
        margin-top: 20px;
        width: 100% !important;
        font-weight: normal;
    }

    .out-fr-practice-info-wrapper .out-fr-practice-info-content {
        margin-top: 20px;
    }

    .out-fr-learner-wrapper {
        padding: 14px;
        font-weight: 300;
    }
}