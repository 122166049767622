@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');

body {
  font-family: Oswald, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: transparent;
  line-height: 1.3;
  /* height: 100%; */
 }
 
 .wrap-clas{
   margin-left: 0px;
   margin-right: 0px;
 }

 input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
 }
 
 .no-suggestions {
  position: absolute;
  color: crimson;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin: 5px;
 }
 
 .suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 205px;
  overflow-y: auto;
  padding-left: 0;
  z-index: 999;
  width: 100%;
 }
 
 .suggestions li {
  padding: 0.5rem;
 }
 
 .suggestion-active {
  background-color: #06b1ab;
  color: #fff;
  cursor: pointer;
  /* font-weight: 700; */
 }

 .suggestions li:hover {
  background-color: #CCC;
  color: #fff;
  cursor: pointer;
  /* font-weight: 700; */
 }
 
 .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
 }
 
 .ifr-sec{
  /* padding: 10px 50px; */
  background: #fff;
  border-radius: 5px;
  border: 6px solid rgb(246, 234, 234, .5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  padding:0vh 4vw 1vh;
  /* height: 790px;
  overflow-y: auto; */
 }


 .main-text{
  padding: 30px 0px;
  text-align: center;
  color: #5C5C5C;
 }

 .main-text p {
    font-weight: 500;
    font-size: 20px;
 }

 .search-form{
  width: 45%;
  margin: 0 auto;
  position: relative;
 }
 
 .search-sec input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #0cc1b8;
  border-radius: 5px;
  height: 50px;
  outline: none;
  line-height: 1;
  font-weight: 300;
 }
 
 .search-sec input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #0cc1b8;
  font-size: 22px;
 }
 
 .search-sec input[type="text"]::-moz-placeholder { /* Firefox 19+ */
  color: #0cc1b8;
  font-size: 22px;
 }
 
 .search-sec input[type="text"]:-ms-input-placeholder { /* IE 10+ */
  color: #0cc1b8;
  font-size: 22px;
 }
 
 .search-sec input[type="text"]:-moz-placeholder { /* Firefox 18- */
  color: #0cc1b8;
  font-size: 22px;
 }
 
 .search-sec-icon img {
  position: absolute;
  right: 10px;
  top: 12px;
  width: 24px;
  height: 24px;
 }

 .select-container {
   width: 60%;
   margin-top: 10px;
 }
 
 .dropdown-wrapper {
  width: 100%;
  margin: 30px 0px 10px;
  text-align: center;
  display: inline-flex;
 }

 /* .month-of {} */
 .month-of select {
  outline: none;
  width: 100%;
  padding: 10px;
  border: 2px solid #0cc1b8;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 300;
  /* background: #06b1ab; */
  color: #fff;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  background: url('./images/dropdown-arrow.png') 96% / 8% no-repeat #06b1ab;
 }

 /* For IE10 */
.month-of::-ms-expand {
  display: none;
}

.static-centres-block {
  background-color: #F2F2F2;
  display: flex;
  flex-wrap: wrap;
  padding: 1% 1%;
  margin: 20px 0;
  font-size: 20px;
}

.frame-footer-text{
  margin-top: 20px;
  font-size: 10px; 
  margin-left: 12%;
  margin-right: 10%;
  text-align: center;
  color: #5C5C5C;
}

.static-centre-progress {
  height: 30px;
  font-size: 16px;
  margin-top: 5px;
}

.static-performer-block {
  height: 100%;
  padding: 16px 10px;
  font-weight: 500;
}
.static-centre-text {
  color: #E9511E;
  text-align: center;
}

.static-centre-name {
  color: #0CC1B8;
  text-align: center;
  margin-top: 5px;
}

 /* 
  Results Page:
*/
hr {
  margin: 0 10px;
}

.pass-rate-section img {
  position: absolute;
  top: 80px;
  height: 100px;
  width: 100px;
  left: 48px;
  -moz-transform: rotate(-8deg);
  -webkit-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg);
}

.pass-rate-section1 img {
  position: absolute;
  top: 80px;
  height: 100px;
  width: 100px;
  right: 54px;
  -moz-transform: rotate(14deg);
  -webkit-transform: rotate(14deg);
  -o-transform: rotate(14deg);
  -ms-transform: rotate(14deg);
  transform: rotate(14deg);
}

.main-bd{
  padding: 80px;
  background: #06b1ab;
}

.main-bd-inner {
  padding: 10px 50px;
  background: #fff;
  border-radius: 5px;
  border: 8px solid rgba(255, 255, 255, .5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

.main-bd-upper{
  text-align: center;
  /* padding:0px 9vw; */
}

.prob-progress .progress{
  height: 50px;
  font-size: 24px;
  font-weight: 300;
}

.main-bd-upper h2 span {
  color: #e9511e;
  padding: 0 10px 5px;
  font-size: 30px;
  font-weight: bold;
}

.validation-message {
  color: crimson;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.prob-progress{
  width: 45%;
  margin:0 auto;
  padding: 10px 0 30px 0;
}

.progress-bar {
  background-color: #0cc1b8;
  text-align: right;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  width: 0;
  font-weight: bold;
  animation: progress 1.5s ease-in-out forwards;
}

.progress-bar .title {
  opacity: 0;
  animation: show 0.35s forwards ease-in-out 0.5s;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
} 
@keyframes show  {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-bd-upper h5{
  color: #0cc1b8;
  margin-top: 10px;
  margin: 0px 107x;
}

.share-icons {
  text-align: center;
  margin-top: -15px;
}

.share-icon-fb {
  /* width: 210px; */
}

.share-icon-twitter {
  /* height: 42px; */
}

.rate-box-title{
  text-align: center;
  background: #E6E6E6;
  padding: 15px 0.5vw;
  color: #e9511e;
  height: 70px;
}

.rate-box:nth-child(2) .rate-box-title h6 {
  margin-top: 10px;
}

.rate-box-title h6{
  font-size: 18px;
  /* font-weight: 400; */
}

.rate-box-content h4{
  text-align:center;
}

.rate-box-content {
  padding: 1vh 2vw;
  background:#F2F2F2;
  color: #0cc1b8;
  height: 250px;
  overflow-y: auto;
  position:relative;
}

.pass-rate-stat h6 {
  font-size: 16px;
  font-weight: 300;
}

.rate-box-tbl{
  width: 100%;
  color: white;
  /* border: 3px solid #F2F2F2; */
}

.rate-box-tbl tr{
  border-bottom: 5px solid #F2F2F2;
  background-color: #0cc1b8;
}

.month-tbl tr:nth-child(3){
  border-bottom: 5px solid #F2F2F2;;
  background-color: #e9511e;
}

.rate-box-tbl tr td:last-child{
  text-align: right;
  padding-right: 5px;
  font-weight: bold;
}

.rate-box-tbl tr td{
  padding: 5px 0;
  text-align: left;
  padding-left: 5px;
}

.rate-box-tbl tr td:nth-child(1){
  font-weight: 300;  
}

.year-info-asterisk {
  font-size: 12px;
  color: #B0B0B0;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 20px;
  font-style: normal !important;
}

.progress{
  background-color: #d2d2d2;
  /* font-weight: bold; */
}

.rate-box-content .progress{
  height: 30px;
}

.pass-rate-stat {
  margin-top: 35px;
}

.button-sec{
  text-align: center;
  margin-top: 10px;
}

.button-sec button[type="button"]{
  outline: none;
  font-size: 22px;
  font-weight: 100;
  padding: 8px 50px;
  border-radius: 5px;
  margin-top: 5px;
  color: #fff;
  border: 2px solid #e95e26;
  background-image: linear-gradient(#e95e26, #e9a849);
  opacity: 0.8;
  -moz-transition: opacity 0.45s ease-in-out;
  -webkit-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
}

.button-sec button[type="button"]:hover{
  /* opacity: 1; */
  background-image: linear-gradient( #e9a849, #e95e26);
}


/* Portal content */
.spacer {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  /* font-weight: 500;
  color: #5C5C5C; */
}

.out-fr-heading-txt{
  color: #0CC1B8;
  font-size: 24px;
  padding-bottom: 20px;
}

.out-fr-first-txt {
  margin-top: 30px;
  width: 56%;
  margin: 0 auto;
}

.out-fr-first-txt1 {
  width: 56%;
  margin: 0 auto;
  margin-top: 20px;
}

.out-fr-second-txt1 {
  width: 70%;
}

.out-fr-third-txt {
  width: 84%;
}

.out-fr-second-txt1, .out-fr-third-txt, .out-fr-fifth-txt, .out-fr-sign-now {
  margin: 24px auto;
}

.out-fr-second-txt1 a, .out-fr-third-txt a, .out-fr-fifth-txt a, .out-fr-sign-now a {
  color: black;
  font-weight: 500;
  text-decoration: underline;
  text-underline-position: under;
}

.out-fr-second-txt {
  color: #8B8B8B;
  margin-top: 20px;
}

.out-fr-fourth-txt {
  font-size: 24px;
  margin-top: 20px;
  font-weight: 500;
  color: #e95e26;
}

.out-fr-fifth-txt {
  width: 64%;
}

.out-fr-practice-info-wrapper {
  width: 44%;
  text-align: center;
  margin: 0 auto;
}

.out-fr-learner-wrapper {
  background-color: #0CC1B8;
  padding: 50px;
  margin-top: 20px;
  color: white;
  font-weight: normal;
}

.out-fr-learner-wrapper-btn-div {
  background-color: #e95e26;
  padding: 10px;
  width:auto;
  display:inline-block;
}

.out-fr-learner-wrapper a {
  color: white;
  font-weight: normal;
}

.out-fr-learner-wrapper a:hover {
  text-decoration: none;
}

.out-fr-practice-info-wrapper .out-fr-practice-info-content {
  margin-top: 30px;
}

.out-fr-practice-info-wrapper .out-fr-practice-info-content div:nth-child(2) {
  font-weight: 300;
  width: 82%;
  margin: 10px auto;
}

.out-fr-practice-info-wrapper > div {
  text-align: center;
  color: #0CC1B8;
}

.frame-caveat-txt {
  font-size: 12px;
  margin-top: 5px;
}